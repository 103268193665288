import React from 'react';

interface NewsProps {
    t: (key: string) => string;
}

const News: React.FC<NewsProps> = ({t}) => {

    return (
        <div className="App">
            news
        </div>
    )

}

export default News;
