import React from 'react';

interface AboutProjectProps {
    t: (key: string) => string;
}

const AboutProject: React.FC<AboutProjectProps> = ({t}) => {

    return (
        <div className="App">
            about project
        </div>
    )

}

export default AboutProject;
