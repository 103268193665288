import React from 'react';

interface FormBirthDateProps {
    t: (key: string) => string;
}

const FormBirthDate: React.FC<FormBirthDateProps> = ({t}) => {

    return (
        <div className="App">
            <h1> hello form-birth-date </h1>
            {t('screen_birth_date')}
        </div>
    )

}

export default FormBirthDate;
