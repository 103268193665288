import React from 'react';

interface BrochuresProps {
    t: (key: string) => string;
}

const Brochures: React.FC<BrochuresProps> = ({t}) => {

    return (
        <div className="App">
            Brochures
        </div>
    )

}

export default Brochures;
