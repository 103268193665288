import React from 'react';
import {FaHandHoldingMedical, FaPeopleGroup} from 'react-icons/fa6';
import './questionnairechoice.css';

interface QuestionnaireChoiceProps {
    t: (key: string) => string;
}

const QuestionnaireChoice: React.FC<QuestionnaireChoiceProps> = ({t}) => {

    return (
        <div className="choice-content__wrapper">
            <div className="professionals-patients__wrapper">
                {t('menu_for_professionals')} <FaHandHoldingMedical size={60}/>
            </div>
            <div className="professionals-patients__wrapper">
                {t('menu_for_patients')} <FaPeopleGroup size={60}/>
            </div>
        </div>
    )

}

export default QuestionnaireChoice;
