import React from 'react';

interface QandAProps {
    t: (key: string) => string;
}

const QandA: React.FC<QandAProps> = ({t}) => {

    return (
        <div className="App">
            Q&A
        </div>
    )

}

export default QandA;
