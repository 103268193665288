import React from 'react';
import './App.css';
import './i18n';
import { Route, Routes } from 'react-router-dom';
import MainScreen from './components/questionnaire/screens/main-screen/MainScreen';
import FormBirthDate from './components/questionnaire/screens/form-birth-date/FormBirthDate';
import {useTranslation} from 'react-i18next';
import QuestionnaireChoice from './components/questionnaire/screens/questionnaire-choice/QuestionnaireChoice';
import QandA from './components/questionnaire/screens/q-and-a/QandA';
import News from './components/questionnaire/screens/news/News';
import Brochures from './components/questionnaire/screens/brochures/Brochures';
import AboutProject from './components/questionnaire/screens/about-project/AboutProject';

function App() {

    const {t, i18n} = useTranslation();

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language).then(() => {})
    }

    return (
        <Routes>
            <Route path="/" element={<MainScreen t={t} changeLanguage={changeLanguage} />}>
                <Route index element={<QuestionnaireChoice t={t} />} />
                <Route path="professionals" element={<FormBirthDate t={t} />} />
                <Route path="about" element={<AboutProject t={t} />} />
                <Route path="brochures" element={<Brochures t={t} />} />
                <Route path="qanda" element={<QandA t={t} />} />
                <Route path="news" element={<News t={t} />} />
            </Route>
        </Routes>
    );
}

export default App;
